/**************  
VARIABLES
***************/
.site-header.grid-container.fluid .menu-section .right-bar .site-navigation .side-menu {
  display: none;
}
.site-header.grid-container.fluid .quick-menu {
  right: 0;
}

.home.section-one.grid-container.fluid {
  padding: 0;
  position: relative;
}
.home.section-one.grid-container.fluid .triangle {
  z-index: 1;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 31rem;
  background: url("/driehoek.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
}
.home.section-one.grid-container.fluid .banner-section {
  min-height: 75vh;
  position: relative;
  max-width: 100%;
  overflow: hidden;
}
@media screen and (max-width: 39.9375em) {
  .home.section-one.grid-container.fluid .banner-section {
    min-height: 60vh;
  }
}
.home.section-one.grid-container.fluid .banner-section .img video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  -o-object-fit: cover;
  object-fit: cover;
  max-width: 100%;
}
.home.section-one.grid-container.fluid .banner-section .img img {
  -webkit-animation: SPINIMAGE 800s linear infinite;
  position: absolute;
  top: -30%;
  left: -10%;
  min-width: 120vw;
  -o-object-fit: cover;
  object-fit: cover;
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-one.grid-container.fluid .banner-section .img img {
    top: -30%;
    left: -70%;
    min-width: auto;
    min-height: 120vh;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-one.grid-container.fluid .banner-section .img img {
    top: -20%;
    left: -10%;
    min-width: 120vw;
    min-height: 80vh;
  }
}
@media only screen and (max-width: 1500px) and (min-width: 769px) {
  .home.section-one.grid-container.fluid .text-section {
    margin-left: 5rem;
  }
}
.home.section-one.grid-container.fluid .text-section .intro {
  position: relative;
  z-index: 1;
  background: #008f5c;
  padding: 2.5rem;
}
@media screen and (min-width: 64em) {
  .home.section-one.grid-container.fluid .text-section .intro {
    margin-top: -7.5rem;
  }
}
.home.section-one.grid-container.fluid .text-section .intro p, .home.section-one.grid-container.fluid .text-section .intro a, .home.section-one.grid-container.fluid .text-section .intro h1, .home.section-one.grid-container.fluid .text-section .intro h2, .home.section-one.grid-container.fluid .text-section .intro h3, .home.section-one.grid-container.fluid .text-section .intro h4, .home.section-one.grid-container.fluid .text-section .intro li {
  color: #fff;
}
.home.section-one.grid-container.fluid .text-section .intro p {
  font-weight: bold;
}
@media screen and (min-width: 40em) {
  .home.section-one.grid-container.fluid .text-section .intro p {
    line-height: 3.6rem;
    font-size: 2.2rem;
  }
}
.home.section-two.grid-container.fluid {
  overflow-x: hidden;
}
@media screen and (min-width: 64em) {
  .home.section-two.grid-container.fluid {
    padding-top: 10rem;
  }
}
.home.section-two.grid-container.fluid .intro {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  position: relative;
  margin-bottom: 5rem;
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-two.grid-container.fluid .intro {
    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-two.grid-container.fluid .intro {
    margin-bottom: 3rem;
  }
}
.home.section-two.grid-container.fluid .intro h1 {
  font-family: "ballinger-x-condensed", sans-serif;
  display: inline-block;
  margin-bottom: 0;
  line-height: 4.6rem;
  margin-left: 3rem;
}
.home.section-two.grid-container.fluid .intro .line-block {
  left: -100%;
}
.home.section-two.grid-container.fluid .tentoonstelling .img {
  position: relative;
}
.home.section-two.grid-container.fluid .tentoonstelling .img .image-wrapper {
  position: relative;
}
.home.section-two.grid-container.fluid .tentoonstelling .img .image-wrapper img {
  width: 100%;
}
.home.section-two.grid-container.fluid .tentoonstelling .img .image-wrapper .tag {
  position: absolute;
  left: 3rem;
  bottom: -1.3rem;
  color: #008f5c;
  padding: 0 1rem;
  background: #fff;
}
@media screen and (min-width: 64em) {
  .home.section-two.grid-container.fluid .tentoonstelling .txt {
    padding: 0 5rem;
    margin-top: -1.1rem;
  }
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-two.grid-container.fluid .tentoonstelling .txt {
    padding: 0 0 0 5rem;
    margin-top: 0;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-two.grid-container.fluid .tentoonstelling .txt {
    padding-top: 2rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-two.grid-container.fluid .tentoonstelling .txt {
    padding-top: 1rem;
  }
}
.home.section-two.grid-container.fluid .tentoonstelling .txt .title a {
  color: #000;
}
.home.section-two.grid-container.fluid .tentoonstelling .txt .date {
  margin: 1rem 0 2rem;
  background: #008f5c;
  width: auto;
  display: inline-block;
  padding: 0.5rem 1.5rem;
}
.home.section-two.grid-container.fluid .tentoonstelling .txt .date p {
  color: #fff;
  display: inline-block;
  margin: 0;
}
.home.section-two.grid-container.fluid .tentoonstelling .txt .excerpt {
  background: rgba(255, 255, 255, 0.85);
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 64em) {
  .home.section-two.grid-container.fluid .tentoonstelling .txt .excerpt {
    margin-left: -20rem;
    padding: 2.5rem;
  }
}
.home.section-two.grid-container.fluid .tentoonstelling .txt .button1 {
  margin-top: 3rem;
}
.home.section-three.grid-container.fluid {
  overflow-x: hidden;
  position: relative;
}
@media screen and (min-width: 64em) {
  .home.section-three.grid-container.fluid {
    padding-right: 0;
    padding-bottom: 0;
  }
}
.home.section-three.grid-container.fluid .intro {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  position: relative;
  margin-bottom: 5rem;
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-three.grid-container.fluid .intro {
    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-three.grid-container.fluid .intro {
    margin-bottom: 3rem;
  }
}
.home.section-three.grid-container.fluid .intro h1 {
  font-family: "ballinger-x-condensed", sans-serif;
  display: inline-block;
  margin-bottom: 0;
  line-height: 4.6rem;
  margin-right: 3rem;
}
.home.section-three.grid-container.fluid .intro .line-block {
  right: -100%;
}
.home.section-three.grid-container.fluid .grey-block {
  width: 85%;
  height: 48rem;
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
  background: #E2E2E3;
  z-index: 0;
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-three.grid-container.fluid .grey-block {
    bottom: 1rem;
  }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-three.grid-container.fluid .grey-block {
    bottom: 15rem;
  }
}
.home.section-three.grid-container.fluid .row-2 {
  position: relative;
}
@media screen and (min-width: 64em) {
  .home.section-three.grid-container.fluid .row-2 {
    padding: 1rem 5rem 1rem 0;
  }
}
.home.section-three.grid-container.fluid .row-2 .text {
  padding: 3rem;
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-three.grid-container.fluid .row-2 .text {
    padding: 0 0 3rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-three.grid-container.fluid .row-2 .text {
    padding: 3rem 0 1rem;
  }
}
.home.section-three.grid-container.fluid .row-2 .item {
  padding: 1.5rem 2rem;
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-three.grid-container.fluid .row-2 .item {
    padding: 1rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-three.grid-container.fluid .row-2 .item {
    padding: 1rem 0;
  }
}
.home.section-three.grid-container.fluid .row-2 .item a .img {
  position: relative;
  height: 25rem;
  width: 100%;
  overflow: hidden;
}
.home.section-three.grid-container.fluid .row-2 .item a .img img {
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}
.home.section-three.grid-container.fluid .row-2 .item a .txt p {
  position: relative;
  z-index: 1;
  padding: 0.1rem 1.5rem;
  background: #000;
  color: #fff;
  display: inline-block;
  top: -1.5rem;
  margin-left: 3rem;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-three.grid-container.fluid .row-2 .item a .txt p {
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-left: 1rem;
  }
}
.home.section-three.grid-container.fluid .row-2 .item a:hover .txt p {
  background: #008f5c;
  top: -2rem;
}
@media screen and (min-width: 64em) {
  .home.section-four.grid-container.fluid {
    padding-top: 15rem;
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-four.grid-container.fluid {
    padding-right: 0;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-four.grid-container.fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media screen and (min-width: 64em) {
  .home.section-four.grid-container.fluid .intro {
    padding-left: 5rem;
  }
}
.home.section-four.grid-container.fluid .intro .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  position: relative;
  margin-bottom: 5rem;
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .home.section-four.grid-container.fluid .intro .wrapper {
    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-four.grid-container.fluid .intro .wrapper {
    margin-bottom: 3rem;
  }
}
.home.section-four.grid-container.fluid .intro .wrapper h1 {
  font-family: "ballinger-x-condensed", sans-serif;
  display: inline-block;
  margin-bottom: 0;
  line-height: 4.6rem;
  margin-left: 3rem;
}
.home.section-four.grid-container.fluid .intro .wrapper .line-block {
  width: 200%;
  left: -200%;
}
.home.section-four.grid-container.fluid .news-slider {
  width: 100%;
}
@media screen and (min-width: 64em) {
  .home.section-four.grid-container.fluid .news-slider {
    padding: 0 15rem;
  }
}
.home.section-four.grid-container.fluid .news-slider .item {
  padding: 2rem;
  outline: none;
  width: 33.3333333333%;
  display: block;
}
@media screen and (min-width: 64em) {
  .home.section-four.grid-container.fluid .news-slider .item {
    padding: 3rem;
  }
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-four.grid-container.fluid .news-slider .item {
    padding: 2rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-four.grid-container.fluid .news-slider .item {
    width: 80%;
    padding: 1rem;
  }
}
.home.section-four.grid-container.fluid .news-slider .item .image {
  position: relative;
  height: 40rem;
  width: 100%;
  display: block;
  overflow: hidden;
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-four.grid-container.fluid .news-slider .item .image {
    height: 30rem;
  }
}
@media screen and (max-width: 39.9375em) {
  .home.section-four.grid-container.fluid .news-slider .item .image {
    height: 35rem;
  }
}
.home.section-four.grid-container.fluid .news-slider .item .image img {
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}
.home.section-four.grid-container.fluid .news-slider .item .content {
  position: relative;
  top: -2rem;
  padding: 0 2rem;
  -webkit-transition: 0.1s all ease-in-out;
  transition: 0.1s all ease-in-out;
}
@media screen and (max-width: 39.9375em) {
  .home.section-four.grid-container.fluid .news-slider .item .content {
    top: -2rem;
  }
}
.home.section-four.grid-container.fluid .news-slider .item .content .inner {
  padding: 0.25rem 1.25rem 0;
  background: #fff;
  width: auto;
  display: inline-block;
}
@media screen and (min-width: 64em) {
  .home.section-four.grid-container.fluid .news-slider .item .title-wrapper .title {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}
.home.section-four.grid-container.fluid .news-slider .item:hover .content {
  top: -3rem;
}
@media screen and (max-width: 39.9375em) {
  .home.section-four.grid-container.fluid .news-slider .item:hover .content {
    top: -2.5rem;
  }
}
.home.section-four.grid-container.fluid .news-slider .slick-prev, .home.section-four.grid-container.fluid .news-slider .slick-next {
  z-index: 2;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.home.section-four.grid-container.fluid .news-slider .slick-prev:before, .home.section-four.grid-container.fluid .news-slider .slick-next:before {
  font-size: 4rem;
  color: #000;
}
@media screen and (max-width: 39.9375em) {
  .home.section-four.grid-container.fluid .news-slider .slick-prev, .home.section-four.grid-container.fluid .news-slider .slick-next {
    display: none !important;
  }
}
.home.section-four.grid-container.fluid .news-slider .slick-prev {
  left: 5rem;
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-four.grid-container.fluid .news-slider .slick-prev {
    left: 5rem;
  }
}
.home.section-four.grid-container.fluid .news-slider .slick-prev:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-right: 20px solid #ababab;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transition: all 0.1s all ease-in-out;
  transition: all 0.1s all ease-in-out;
}
.home.section-four.grid-container.fluid .news-slider .slick-prev:hover:before {
  border-right: 20px solid #008f5c;
}
.home.section-four.grid-container.fluid .news-slider .slick-next {
  right: 5rem;
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .home.section-four.grid-container.fluid .news-slider .slick-next {
    right: 5rem;
  }
}
.home.section-four.grid-container.fluid .news-slider .slick-next:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 20px solid #ababab;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transition: all 0.1s all ease-in-out;
  transition: all 0.1s all ease-in-out;
}
.home.section-four.grid-container.fluid .news-slider .slick-next:hover:before {
  border-left: 20px solid #008f5c;
}